



















































import { Component, Prop } from "vue-property-decorator";
import ErrorMessageHandlerMixin from "@/misc/ErrorMessageHandler.mixins";
import { mixins } from "vue-class-component";
import User from "@/models/User.model";
import { Gender } from "@/enum/UserTitle.enum";
import { UserDegree } from "@/enum/UserDegree";
import { AUTH_STORE_NAME } from "@/store/auth.store";
import { namespace } from "vuex-class";
import IconTooltipComponent from "@/components/IconTooltip.component.vue";
import { UserType } from "@/enum/UserType.enum";
import Application from "@/models/Application";
import { APPLICATION_STORE_NAME, ApplicationStoreActions, ApplicationStoreGetters } from "@/store/application.store";

const AuthStore = namespace(AUTH_STORE_NAME);
const ApplicationStore = namespace(APPLICATION_STORE_NAME);
@Component({
  components: {
    IconTooltip: IconTooltipComponent
  },
})
export default class DuplicatesListComponent extends mixins(ErrorMessageHandlerMixin) {
  @Prop({ default: Application.parseFromObject({}) })
  public application!: Application;

  @ApplicationStore.Getter(ApplicationStoreGetters.DUPLICATIONS)
  private users!: User[];

  @ApplicationStore.Action(ApplicationStoreActions.ASSIGN_APPLICATION)
  private assignApplication!: (payload: {
    app: Application,
    user: User
  }) => Promise<any>;

  private selectedUser: User = User.parseFromObject({});

  private isCustomer(user: User) {
    return user.type === UserType.CUSTOMER;
  }

  /**
   * different gender options
   * @private
   */
  private gOptions = [Gender.MALE, Gender.FEMALE, Gender.DIVERSE, Gender.NOT_SET];

  /**
   * returns translated genders based on the gender enum
   * @private
   */
  private get genderOptions() {
    return this.gOptions.map((n: Gender) => this.$t('GENERAL.GENDER.' + n));
  }

  /**
   * Loading State bool
   * @private
   */
  private isLoading: boolean = false;

  /**
   * Check if Form is Valid
   * if valid try to Register a new Account and listen for API Responses
   * If Invalid return so Vuelidate handles Errors first
   * @private
   */
  private async changeUserInfo(user: User) {
    // Form is Valid try to Register a new Account
    try {
      // Set Loading to true
      this.isLoading = true;
      await this.assignApplication( {app: this.application, user: user });
      this.$notifySuccessSimplified("GENERAL.NOTIFICATIONS.ASSINGED_APPLICATION");
      this.dismiss();
    } catch (e) {
      this.$handleError(e, () => {
        switch (e.status) {
          default:
            this.$notifyErrorSimplified("GENERAL.NOTIFICATIONS.ERROR_GENERAL");
        }
      });
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * is called when the dialog should be dismissed, calls close dialog action
   * @param reload
   * @private
   */
  private dismiss(reload: boolean = false) {
    this.$emit('closeDialog', reload);
  }

  private isCompany(user: User): boolean {
    return user.type === UserType.COMPANY;
  }

  private headers: any = [
    {
      text: this.$t('GENERAL.NAME'),
      align: "start",
      value: "firstName",
      width: "15%",
    },
    {
      text: this.$t('GENERAL.STREET'),
      align: "center",
      value: "address.street",
      width: "15%",
    },
    {
      text: this.$t('GENERAL.HOUSE_NO'),
      align: "center",
      value: "address.houseNr",
      width: "10%",
    },
    {
      text: this.$t('GENERAL.ZIP'),
      align: "center",
      value: "address.zip",
      width: "10%",
    },
    {
      text: this.$t('GENERAL.CITY'),
      align: "center",
      value: "address.city",
      width: "15%",
    },
    {
      text: this.$t('GENERAL.EMAIL'),
      align: "start",
      value: "email",
      width: "20%",
    },
    {
      text: this.$t('GENERAL.ACTIONS'),
      sortable: false,
      align: "end",
      value: "company",
      width: "20%",
    },
  ];
}
