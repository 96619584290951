var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-width background"},[_c('v-row',{staticClass:"align-center mr-5 ml-5 pt-10 pb-5"},[_c('h2',{staticClass:"ml-5"},[_vm._v(_vm._s(_vm.$t('EMPLOYEES.DUPLICATES')))]),_c('v-spacer'),_c('div',{staticClass:"d-flex justify-end pt-4 pr-4"},[_c('v-btn',{attrs:{"icon":"","text":""},on:{"click":function($event){return _vm.dismiss()}}},[_c('v-img',{attrs:{"src":require('../assets/icons/close.png'),"width":"32"}})],1)],1)],1),_c('div',{staticClass:"mr-10 ml-10"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.users,"item-key":"name"},scopedSlots:_vm._u([{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [(_vm.isCustomer(item))?_c('span',[_vm._v(_vm._s(item.firstName + " " + item.lastName))]):_vm._e(),(_vm.isCompany(item))?_c('span',[_vm._v(_vm._s(item.company))]):_vm._e()]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.address.street))])]}},{key:"item.address.houseNr",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.address.houseNr))])]}},{key:"item.address.zip",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.address.zip))])]}},{key:"item.address.city",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.address.city))])]}},{key:"item.company",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-spacer'),_c('a',{staticClass:"align-center text-center d-flex ",on:{"click":function($event){return _vm.changeUserInfo(item)}}},[_c('img',{staticClass:"pr-2",attrs:{"src":require("@/assets/svg/check-circle.svg")}}),(item)?_c('span',[_vm._v(_vm._s(_vm.$t('GENERAL.ASSIGN')))]):_vm._e()])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }